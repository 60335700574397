import React from 'react';

import getCDNURL from '@/utils/helpers/getCDNURL';

interface ArticleCardProps {
	imageUrl: string;
	title: string;
	description?: string;
	type?: 'pagination' | 'latest' | 'popular';
	showDate?: boolean;
	publishedAt?: string;
	alt?: string;
}

const ArticleCard: React.FC<ArticleCardProps> = ({ imageUrl, title, description, type = 'pagination', showDate, publishedAt, alt }) => {
	const typeStyles = {
		pagination: 'w-full max-w-[382px]',
		latest: 'w-full max-w-[799px]',
		popular: 'w-full max-w-[382px]'
	};
	const imageAspectRatio = {
		pagination: 'aspect-[382/326]',
		latest: 'aspect-[799/397]',
		popular: 'aspect-[382/326]'
	};

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
	};

	return (
		<div
			className={`flex cursor-pointer flex-col rounded-lg bg-white p-4 text-gray-900 shadow-md hover:bg-blue-500 hover:text-white sm:shadow-transparent ${typeStyles[type]}`}
		>
			<div className={`${imageAspectRatio[type]} overflow-hidden rounded sm:rounded-2xl`}>
				<img
					src={`${getCDNURL(imageUrl || `${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/placeholder.png`, 800, 320)}`}
					alt={alt ? alt : title}
					className="size-full object-contain"
				/>
			</div>

			<div>
				{showDate && type === 'pagination' && publishedAt && <p className="my-2 text-xs">{formatDate(publishedAt)}</p>}
				<h3 className="mt-2 line-clamp-1 text-base font-medium sm:text-xl">{title}</h3>
				{description && <p className="mb-5 mt-2 hidden text-sm sm:line-clamp-2">{description}</p>}
			</div>
		</div>
	);
};

export default ArticleCard;
