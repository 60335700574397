import { z } from 'zod';

import { SessionData } from '@/lib/sessionsv2/types';
import { SystemModuleType } from '@/types/types';

export enum ActivityType {
	VIEW = 1,
	CLICK = 2
}

export const impressionBodySchema = z.object({
	user_key: z.string().uuid(),
	activity_type: z.nativeEnum(ActivityType),
	module_for: z.nativeEnum(SystemModuleType),
	module_uuid: z.string().min(1)
});

export type AddImpressionBody = z.infer<typeof impressionBodySchema>;

export interface ImpressionWrapperProps {
	moduleFor: SystemModuleType;
	moduleId: string;
	children: React.ReactNode;
	className?: string;
	disableClickTrack?: boolean;
	disableImpressionTrack?: boolean;
	impressionThreshold?: number;
}

export interface ImpressionContextInterface {
	userId: string;
}

export interface ImpressionProviderProps {
	children: React.ReactNode;
	session?: SessionData | null;
}
