'use client';

import { Button } from '@pickleballinc/react-ui';
import moment from 'moment';
import Link from 'next/link';
import React from 'react';

import { FeaturedTournamentCard } from '@/tournament/components/FeaturedTournamentCard';
import { FeaturedTournamentsRes } from '@/utils/actions/getFeaturedTournaments';
import getPBracketsCDNURL from '@/utils/helpers/getPBracketsCDNURL';

interface FeaturedContainerProps {
	featuredTournaments: FeaturedTournamentsRes;
	initialFeaturedTournamentsCount: number;
}

export const FeaturedContainer = ({ featuredTournaments, initialFeaturedTournamentsCount }: FeaturedContainerProps) => {
	const handleCardClick = async (e: React.MouseEvent, slugOrTournamentId: string) => {
		e.preventDefault();
		const url = `${process.env.NEXT_PUBLIC_PT_URL}/tournaments/${slugOrTournamentId}`;
		setTimeout(() => {
			window.location.href = url;
		}, 100);
	};

	return (
		<>
			{featuredTournaments && featuredTournaments.result.length > 0 && (
				<div className="bg-white py-8">
					<div className="mx-auto flex max-w-screen-2xl flex-row items-baseline justify-between p-4">
						<h1 className="text-2xl font-bold text-gray-900" tabIndex={0}>
							Featured Tournaments
						</h1>
						{featuredTournaments.totalRecords >= initialFeaturedTournamentsCount && (
							<a href={`${process.env.NEXT_PUBLIC_PT_URL}/search?featured=true`}>
								<Button className="!text-blue-500" variant="link">
									Show all
								</Button>
							</a>
						)}
					</div>
					<div className="mx-auto grid max-w-screen-2xl grid-cols-1 items-stretch gap-4 p-4 sm:grid-cols-2 md:gap-8 lg:grid-cols-3 xl:grid-cols-4">
						{featuredTournaments.result.map((tourney) => (
							<Link
								key={tourney.TournamentID}
								onClick={(e) => handleCardClick(e, tourney.slug || tourney.TournamentID)}
								href={`${process.env.NEXT_PUBLIC_PT_URL}/tournaments/${tourney.slug || tourney.TournamentID}`}
							>
								<FeaturedTournamentCard
									image={getPBracketsCDNURL(tourney.Logo, 243, 192)}
									title={tourney.Title}
									duration={`${moment.parseZone(tourney.TourneyFromDate).format('MMM DD, YYYY')} - ${moment
										.parseZone(tourney.TourneyToDate)
										.format('MMM DD, YYYY')}`}
									location={`${tourney.LocationCity}, ${tourney.LocationState}, ${tourney.LocationCountry}`}
									tournamentId={tourney.TournamentID}
								/>
							</Link>
						))}
					</div>
				</div>
			)}
		</>
	);
};
