'use client';

import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/react-ui';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { ImpressionWrapper } from '@/components/ImpressionWrapper';
import TournamentCustomAd from '@/components/TournamentCustomAd';
import { EventCard, EventCardProps } from '@/tournament/components/EventCard';
import { SystemModuleType } from '@/types/types';

interface SliderSectionProps {
	title: string;
	subtitle?: string;
	customClassName?: string;
	data: EventCardProps[];
	showAllRedirectURL?: string;
	renderCardAd?: boolean;
	ipAddress?: string;
	recommendedTournaments?: boolean;
}

export const SliderSection = ({
	title,
	customClassName,
	data,
	showAllRedirectURL,
	subtitle,
	renderCardAd,
	ipAddress,
	recommendedTournaments
}: SliderSectionProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const interval = useRef<NodeJS.Timeout | null>(null);

	const prevScroll = () => {
		if (containerRef.current) {
			containerRef.current.scroll({
				left: containerRef.current.scrollLeft - 300,
				behavior: 'smooth'
			});
		}
	};

	const nextScroll = () => {
		if (containerRef.current) {
			containerRef.current.scroll({
				left: containerRef.current.scrollLeft + 300,
				behavior: 'smooth'
			});
		}
	};

	const prevScrollHold = () => {
		interval.current = setInterval(() => {
			prevScroll();
		}, 500);
	};

	const nextScrollHold = () => {
		interval.current = setInterval(() => {
			nextScroll();
		}, 500);
	};

	const handleMouseUp = () => {
		if (interval.current) {
			clearInterval(interval.current);
			interval.current = null;
		}
	};

	const [isFirstItemVisible, setIsFirstItemVisible] = useState(true);
	const [isLastItemVisible, setIsLastItemVisible] = useState(false);

	const handleScroll = () => {
		const container = containerRef.current;

		if (container) {
			const isAtStart = container.scrollLeft === 0;
			const isAtEnd = container.scrollLeft + container.offsetWidth >= container.scrollWidth;

			setIsFirstItemVisible(isAtStart);
			setIsLastItemVisible(isAtEnd);
		}
	};

	useEffect(() => {
		const container = containerRef.current;
		if (container) {
			container.addEventListener('scroll', handleScroll);
		}
		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}

			if (interval.current) {
				clearInterval(interval.current);
			}
		};
	}, []);

	const handleCardClick = async (e: React.MouseEvent, tourneySlug: string) => {
		e.preventDefault();
		const url = `${process.env.NEXT_PUBLIC_PT_URL}/tournaments/${tourneySlug}`;
		setTimeout(() => {
			window.location.href = url;
		}, 100);
	};

	return (
		<div className={classNames('py-8', customClassName)}>
			<div className="mx-auto flex max-w-screen-2xl flex-row items-baseline justify-between px-4">
				<div className="text-2xl font-semibold text-gray-900">
					<h1 tabIndex={0}>{title}</h1>
					{subtitle && <div className="text-base font-medium text-gray-500">{subtitle}</div>}
				</div>

				{showAllRedirectURL && (
					<a href={showAllRedirectURL}>
						<Button className="!text-blue-500" variant="link">
							Show all
						</Button>
					</a>
				)}
			</div>
			<div className="relative mx-auto max-w-screen-2xl px-4">
				<div className="hidden-scrollbar -mb-4 mt-4 overflow-x-auto py-4" ref={containerRef}>
					{!isFirstItemVisible && (
						<button
							type="button"
							className="absolute left-1 top-[30%] z-10 flex size-10 cursor-pointer items-center justify-center rounded-full border border-gray-500 bg-white/70 backdrop-blur-sm transition duration-150 hover:bg-white disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:bg-white lg:size-14 2xl:-left-4"
							onMouseDown={prevScrollHold}
							onTouchStart={prevScrollHold}
							onClick={prevScroll}
							onMouseUp={handleMouseUp}
							onMouseLeave={handleMouseUp}
							onTouchEnd={handleMouseUp}
							role="button"
							aria-label="Previous"
						>
							<FontAwesomeIcon icon={faArrowLeft} className="block text-base text-gray-500" />
						</button>
					)}

					{!isLastItemVisible && (
						<button
							type="button"
							className="absolute right-1 top-[30%] z-10 ml-4 flex size-10 cursor-pointer items-center justify-center rounded-full border border-gray-500 bg-white/70 backdrop-blur-sm transition duration-150 hover:bg-white disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:bg-white lg:size-14 2xl:-right-4 "
							onMouseDown={nextScrollHold}
							onTouchStart={nextScrollHold}
							onClick={nextScroll}
							onMouseUp={handleMouseUp}
							onMouseLeave={handleMouseUp}
							onTouchEnd={handleMouseUp}
							role="button"
							aria-label="Next"
						>
							<FontAwesomeIcon icon={faArrowRight} className="block text-base text-gray-500" />
						</button>
					)}

					<div className="inline-block">
						<div className="flex gap-4 md:gap-8">
							{renderCardAd && (
								<div className="inline-grid max-h-full min-h-full w-[245px]">
									<TournamentCustomAd
										zoneId="788107"
										pageId={100003}
										place={1}
										width={0}
										height={0}
										className="relative"
										ipAddress={ipAddress}
									/>
								</div>
							)}
							{data &&
								data.length > 0 &&
								data.map((tournament) => (
									<div
										onClick={(e) => handleCardClick(e, tournament.slug || tournament.id)}
										key={tournament.id}
										className="h-full w-[245px]"
									>
										{recommendedTournaments ? (
											<ImpressionWrapper moduleId={tournament.id} moduleFor={SystemModuleType.RECOMMENDED_TOURNAMENT}>
												<EventCard {...tournament} />
											</ImpressionWrapper>
										) : (
											<EventCard {...tournament} />
										)}
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
